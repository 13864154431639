import Link from "next/link";
import {FaGithub, FaLinkedin, FaTwitter, FaYoutube} from "react-icons/fa";
import Container from "./container/Container";
import {BLUE_GRADIENT, TEXT_GRADIENT} from "../util/gradient";
import classNames from "../util/tailwind-util";

export default function Footer() {
  return (
    <footer className="mt-8 md:mt-16">
      <Container>
        <div className="relative text-center">
          <h3 className="absolute -mt-4 w-full text-lg md:text-2xl font-bold">
            <span className="bg-background px-6">Let’s talk!</span>
          </h3>
          <div className="p-5 border-l-2 border-t-2 border-r-2 border-special rounded-t-lg">
            <div className="md:columns-3 ">
              <div className="hidden md:block md:space-y-4 pt-4">
                <div className="text-secondary">coded by</div>
                <div>
                  <a href="https://duplxey.com" className={classNames(TEXT_GRADIENT, BLUE_GRADIENT)}>
                    @duplxey
                  </a>
                </div>
              </div>
              <div className="space-y-2 md:space-y-4 pt-4">
                <p className="font-normal text-sm md:text-base">
                  info(at)duplxey.com
                </p>
                <div className="max-w-xs mx-auto">
                  <div className="flex flex-row justify-center space-x-4 text-sm md:text-base">
                    <div className="text-2xl">
                      <Link href="https://github.com/duplxey">
                        <a
                          className="text-default hover:text-secondary duration-200"
                          aria-label="GitHub" title="Nik Tomazic GitHub"
                        >
                          <FaGithub/>
                        </a>
                      </Link>
                    </div>
                    <div className="text-2xl">
                      <Link href="https://www.linkedin.com/in/nik-tomazic/">
                        <a
                          className="text-default hover:text-secondary duration-200"
                          aria-label="LinkedIn" title="Nik Tomazic LinkedIn"
                        >
                          <FaLinkedin/>
                        </a>
                      </Link>
                    </div>
                    <div className="text-2xl">
                      <Link href="https://twitter.com/duplxey">
                        <a
                          className="text-default hover:text-secondary duration-200"
                          aria-label="Twitter" title="Nik Tomazic Twitter"
                        >
                          <FaTwitter/>
                        </a>
                      </Link>
                    </div>
                    <div className="text-2xl">
                      <Link href="https://www.youtube.com/user/serverwatw/videos">
                        <a
                          className="text-default hover:text-secondary duration-200"
                          aria-label="YouTube" title="Nik Tomazic YouTube"
                        >
                          <FaYoutube/>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 md:mt-0 md:space-y-4 pt-4 text-sm md:text-base">
                <div className="text-secondary">designed by</div>
                <div>
                  <a href="https://twitter.com/bycimpo" className={classNames(TEXT_GRADIENT, BLUE_GRADIENT)}>
                    @bycimpo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}