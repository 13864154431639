import Header from "./Header";
import Footer from "./Footer";
import Container from "./container/Container";
import Globes from "./misc/Globes";
import ColorLoader from "./misc/ColorLoader";

export type LayoutProps = {
  children: any;
}

export default function Layout(props: LayoutProps) {
  return (
    <div className="text-default text-base flex flex-col min-h-screen">
      <ColorLoader/>
      <Globes/>
      <Header/>
      <Container>
        {props.children}
      </Container>
      <Footer/>
    </div>
  )
}