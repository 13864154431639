import Link from "next/link";
import Star from "./svg/Star";
import {useRouter} from "next/router";
import classNames from "../util/tailwind-util";

const navigation = [
  {name: "Projects", href: "/projects"},
  {name: "Blog", href: "/blog"},
]

export default function Header() {

  const router = useRouter();

  return (
    <header className="mt-6 md:mt-12">
      <div className="md:container mx-auto">
        <div className="-ml-2 mr-4 md:ml-0 md:mx-4">
          <div className="flex justify-between items-center">
            <Link href={"/"}>
              <a>
                <div className="w-36 h-36 flex justify-center items-center group">
                  <div className="absolute">
                    <Star/>
                  </div>
                  <div
                    className="absolute w-24 h-24 group-hover:w-28 group-hover:h-28 duration-300 border border-2 border-default rounded-full flex justify-center items-center z-50"
                  >
                    <div
                      className="absolute w-16 h-16 group-hover:w-20 group-hover:h-20 duration-300 bg-special-no-wcag text-default rounded-full flex justify-center items-center"
                    >
                      NT
                    </div>
                  </div>
                </div>
              </a>
            </Link>
            <div className="flex space-x-8">
              {navigation.map((item, index) => {
                return (
                  <div key={index}>
                    <Link href={item.href}>
                      <a className={classNames(
                        "text-default hover:text-secondary duration-200 pt-7 pb-1 px-1",
                        router.pathname.includes(item.href) ? "border-b-4 border-special" : "",
                      )}>
                        {item.name}
                      </a>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}