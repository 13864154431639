import '../styles/fonts.css';
import '../styles/globals.css';
import type {AppProps} from 'next/app';
import Layout from "../components/Layout";
import Head from "next/head";
import Script from "next/script";

function MyApp({Component, pageProps}: AppProps) {
  return (
    <Layout>
      <Head>
        <meta charSet="utf-8"/>
        <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
        <title key="title">Nik Tomazic | Full Stack Developer</title>
        <meta key="description" content="Nik Tomazic is a Full Stack Developer who specializes in web and mobile development. He’s experienced in server administration, web development, graphic design and technical writing. He’s always trying his best to work fast and efficient and making things as perfect as possible. He likes learning new things and accepting new challenges." name="description"/>
        <meta key="keywords" content="developer, programmer, portfolio, web, python, javascript, typescript, php, java, django, react, git, linux, server, administration, technical writing" name="keywords"/>
        <meta key="author" content="Nik Tomazic (@duplxey), Jorg Cimperman (@bycimpo)" name="author"/>
        <meta key="og:title" content="Nik Tomazic | Full Stack Developer" property="og:title"/>
        <meta key="og:description" content="Nik Tomazic is a Full Stack Developer who specializes in web and mobile development. He’s experienced in server administration, web development, graphic design and technical writing. He’s always trying his best to work fast and efficient and making things as perfect as possible. He likes learning new things and accepting new challenges." property="og:description"/>
        <meta content="/favicon.ico" property="og:image"/>
      </Head>
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=G-4C0DXDF3BN`} />
      <Script strategy="lazyOnload" id="google-analytics">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4C0DXDF3BN', {
            page_path: window.location.pathname,
            });
          `}
      </Script>
      <Component {...pageProps} />
    </Layout>
  )
}

export default MyApp
