export type ContainerProps = {
  children: any;
}

export default function Container(props: ContainerProps) {
  return (
    <div className="mb-auto md:container mx-auto">
      <div className="mx-8 md:mx-12 lg:mx-24 xl:mx-32 2xl:mx-48">
        {props.children}
      </div>
    </div>
  )
}