import Globe from "../svg/Globe";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {useEffect, useState} from "react";

export default function Globes() {

  const {width, height} = useWindowDimensions();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const calculateGlobeX = () => {
    if (width == null) return 0;
    if (width <= 1024) {
      return -width;
    } else if (width <= 1280) {
      return -850;
    } else if (width <= 1536) {
      return -820;
    } else if (width <= 1920) {
      return -760;
    } else {
      return -570;
    }
  }

  const calculateGlobeY = () => {
    if (height == null) return 0;
    if (height <= 900) {
      return 50;
    } else if (height <= 1500) {
      return height - 950;
    }
  }

  return (
    <>
      {isClient && (
        <>
          <div
            className="absolute hidden lg:block"
            style={{left: calculateGlobeX(), top: 0, zIndex: -100}}
          >
            <Globe/>
          </div>
          <div
            className="absolute hidden lg:block"
            style={{right: calculateGlobeX(), top: calculateGlobeY(), zIndex: -100}}
          >
            <Globe/>
          </div>
        </>
      )}
    </>
  )
}