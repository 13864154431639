export default function Star() {
  return (
    <svg
      width="199" height="199" viewBox="0 0 199 199"
      fill="none" xmlns="http://www.w3.org/2000/svg" id="star"
    >
      <path
        d="M126.37 0.297229L104.189 93.6972L188.666 48.0981L106.937 98.4573L198.915 125.948L105.515 103.767L151.114 188.243L100.755 106.515L73.2642 198.492L95.4455 105.092L10.969 150.691L92.6972 100.332L0.719861 72.8416L94.1198 95.0229L48.5207 10.5463L98.88 92.2746L126.37 0.297229Z"
        fill="#0166FF"
      />
    </svg>
  );
}