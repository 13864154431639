export default function ColorLoader() {
  return (
    <div className="absolute hidden">
      <span className="text-transparent bg-clip-text bg-gradient-to-b bg-gradient-to-b from-[#85A7FF] to-[#0066FF]">
        gradient text
      </span>
      <span className="text-success border-success bg-success">success text</span>
      <span className="text-danger border-danger bg-danger">danger text</span>
      <span className="text-warning border-warning bg-warning">warning text</span>
    </div>
  )
}